<template>
  <div class="app-header">
    <h5>Excelerate - free to use for students</h5>
  </div>
</template>

<script>
export default {
    name: 'StudentHeader',
}
</script>

<style>
.app-header{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background: transparent linear-gradient(270deg, #BC1E73 0%, #5A2871 100%) 0% 0% no-repeat padding-box;
    z-index: 9999; /* because of another element   */
}
.app-header h5 {
color: #fff;
margin:10px;
font-weight: 200;
font-size: 13px;
text-align: center;
}
/* .app-header h5{ 
    color: #fff;
    margin: 10px;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
} */
</style>